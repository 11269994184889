import React, { Component } from "react"
import ReactDOM from "react-dom"
import AnchorLink from "react-anchor-link-smooth-scroll"

class Nav extends Component {
  setNavBounds = () => {
    const offsetFromTop = window.innerHeight - this.offsetFromBottom
    this.props.getNavOffset(offsetFromTop)
  }

  componentDidMount() {
    window.addEventListener("resize", this.setNavBounds)
    const navBounds = ReactDOM.findDOMNode(this).getBoundingClientRect()
    this.offsetFromBottom = navBounds.height
    this.setNavBounds()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setNavBounds)
    this.props.getNavOffset(undefined)
  }

  render() {
    const { isTop } = this.props

    return (
      <div className={"nav" + (isTop ? "" : " sticky solid")}>
        <div className="nav__logo-wrapper">
          <h2 className={"nav__logo" + (isTop ? " hide" : "")}>Jon Mak</h2>
        </div>
        <div className="nav__menu">
          <AnchorLink href="#about">ABOUT</AnchorLink>
          <AnchorLink href="#work">PROJECTS</AnchorLink>
          <AnchorLink href="#contact">CONTACT</AnchorLink>
        </div>
      </div>
    )
  }
}

export default Nav
