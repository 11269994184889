import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Nav from "./nav"
import SEO from "./seo"
import "../style/index.scss"

class Layout extends Component {
  state = {
    isTop: true,
  }

  lightMode = () => {
    document.body.classList.add("light")
    document.body.classList.remove("dark")
  }

  darkMode = () => {
    document.body.classList.add("dark")
    document.body.classList.remove("light")
  }

  handleScroll = () => {
    if (this.navOffset === undefined) return

    const { isTop } = this.state
    const atTheTop = window.pageYOffset <= this.navOffset
    
    if (atTheTop !== isTop) {
      this.setState(() => ({ isTop: atTheTop }))

      if (atTheTop) {
        this.darkMode()
      } else {
        this.lightMode()
      }
    }
  }

  componentDidMount() {
    const atTheTop = window.pageYOffset <= this.navOffset
    this.setState(() => ({ isTop: atTheTop }))

    const { children } = this.props
    if (children === undefined) return

    const isHomepage =
      this.props.children[0].props.className === "home hero wrapper"
    if (isHomepage) {
      document.addEventListener("scroll", this.handleScroll)

      if (atTheTop) {
        this.darkMode()
      } else {
        this.lightMode()
      }
    }
  }

  render() {
    const {
      title,
      phonenumber,
      email,
      trademark,
    } = this.props.data.site.siteMetadata

    return (
      <>
        <Nav
          getNavOffset={el => (this.navOffset = el)}
          isTop={this.state.isTop}
        />
        <SEO title={title} />
        <main>{this.props.children}</main>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            phonenumber
            email
            trademark
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
